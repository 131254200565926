import { CustomFieldTypeInfoFieldTypeEnum, Incident } from "@incident-io/api";
import { renderCustomFieldValues } from "@incident-shared/custom-fields/renderCustomFieldValues";
import { ContentBox } from "@incident-ui";
import { useIsWithinMicrosoftTeamsTab } from "src/contexts/MicrosoftTeamsTabContext";
import { tcx } from "src/utils/tailwind-classes";

// TODO: use the API type instead
type Section = {
  type: string;
  name: string;
  custom_field_ids: string[];
};

export const PostMortemCustomSection = ({
  incident,
  section,
}: {
  incident: Incident;
  section: Section;
}): React.ReactElement => {
  return (
    <ContentBox className="p-6">
      <div className="space-y-3">
        <div className="text-base-bold">{section.name}</div>
        <PostMortemSectionCustomFields
          incident={incident}
          customFieldIds={section.custom_field_ids}
        />
        <div className="flex-row bg-stroke-primary h-[1px] !my-4"></div>
        <div className="text-content-tertiary">Click to start writing</div>
      </div>
    </ContentBox>
  );
};

const PostMortemSectionCustomFields = ({
  incident,
  customFieldIds,
}: {
  incident: Incident;
  customFieldIds: string[];
}): React.ReactElement => {
  const isWithinTeamsTab = useIsWithinMicrosoftTeamsTab();

  const entriesToShow = incident.custom_field_entries.filter(
    ({ custom_field }) => {
      return customFieldIds.includes(custom_field.id);
    },
  );

  return (
    <div className="space-y-1">
      {entriesToShow.map(({ custom_field: customField, values }) => {
        const value = renderCustomFieldValues(
          customField,
          values,
          isWithinTeamsTab,
        );
        const allowWrap =
          customField.field_type === CustomFieldTypeInfoFieldTypeEnum.Text;

        return (
          <div
            className={"flex-center-y text-sm"}
            key={`${customField.name}|${value}`}
          >
            <span className="text-content-tertiary mr-2 w-[155px] flex-none">
              {customField.name}
            </span>
            <div className="flex-center-y max-w-[calc(60%-1rem)]">
              <span
                className={tcx(
                  "max-w-full",
                  allowWrap ? undefined : "truncate",
                )}
              >
                {value}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
