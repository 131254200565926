import { LoadingBar } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

import { useIncident } from "../hooks";
import { PostMortemCustomSection } from "./PostMortemCustomSection";

export const PostMortemTab = ({
  incidentId,
}: {
  incidentId: string | null;
}): React.ReactElement => {
  const { incident, isLoading: incidentLoading } = useIncident(incidentId);

  // TODO: once we have the show endpoint, update this to fetch the default template
  const template = {
    sections: [
      {
        type: "custom",
        name: "Impact",
        custom_field_ids: [
          "01GJ505RZH48CJWRB9ZMW5FM6G",
          "01J5DMKJQAK03AF18YEHDSW1GV",
          "01HAVCNZ9TP389H19N6JNRECGD",
        ],
      },
      {
        type: "custom",
        name: "Root cause",
        custom_field_ids: [],
      },
    ],
  };

  // Be extra sure we don't show this content unless the feature flag is enabled.
  const { postmortemsInHouse: featurePostmortemsInHouse } = useFlags();
  if (!featurePostmortemsInHouse) {
    return <></>;
  }

  if (incidentId == null || incident == null || incidentLoading) {
    return <LoadingBar className="w-full h-28 mt-4" />;
  }

  return (
    <div className="mt-4 space-y-4">
      {template.sections.map((section) => {
        // In a future project, we'll support showing the timeline and follow-ups too.
        if (section.type !== "custom") {
          return null;
        }

        return (
          <PostMortemCustomSection
            key={section.name}
            incident={incident}
            section={section}
          />
        );
      })}
    </div>
  );
};
